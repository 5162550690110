<template>
  <v-snackbar
  v-model="alert.showAlert"
  :timeout="-1">
    {{alert.message}}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="clear"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SnackbarAlert',
  computed: {
    ...mapGetters({
      alert: 'alert/alert',
    }),
  },
  methods: {
    ...mapMutations({
      clear: 'alert/CLEAR_SHOW',
    }),
  },
};
</script>
